import React from "react";

// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';

import Layout from "../../../components/Layout/Layout";

const Article03012021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              ORACLE DATABASE -IDEAL PLATFORM FOR DATA MANAGEMENT AND ARTIFICIAL
              INTELLIGENCE
            </h1>
            <p>
              The amount of data we produce every day is truly mind-boggling.
              2.5 quintillion bytes of data are generated every day at our
              current pace, and this pace is only accelerating with the
              advancement of the Internet of Things (IoT). In the last two years
              alone, over 90 percent of the world's data has been generated!
              Effectively using and managing your data is critical to your
              business. Choosing the right tools can speed up development many
              times over, and the wrong tools can wreak havoc. S&T Mold invites
              you to take part in the MUK partner webinar. During the webinar,
              Oracle data management experts will share their experiences, best
              practices for implementation and use, and how to use Oracle
              Database Options to:
            </p>
            <p className="bullet">Reduce operating loads</p>
            <p className="bullet">Reduce the cost of databases</p>
            <p className="bullet"> Improve operational efficiency</p>
            <p className="bullet"> Increase the security of your databases</p>
            <p className="bullet"> Improve database performance</p>
            <p className="bold">Webinar details:</p>
            <p>Date: 22 January 2021</p>
            <p>Time: 14:00 – 15:30</p>
            <p>
              Speakers: Costin Jiurjiu, Technology & Cloud Solution Engineer,
              Oracle
            </p>
            <p>Artem Voevoda, Oracle Lead Technical Specialist, MUK Group</p>
            <p>Language: RU,RO</p>
            <p className="bold">
              Registration{" "}
              <a href="https://oracle.muk.ua/db_2021_moldova/">Link</a>
            </p>
            <p>We look forward to your participation!</p>
            <p>Your S&T Mold team</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article03012021;
